import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  SectionTitle,
  Row,
  Left,
  RecordIcon,
  Right,
  Owner,
  InterpunctJoin
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'
import Name from 'components/BusinessCard/components/Name'
import { getUsers } from 'selectors/Account'

const Deal = () => {
  const {
    cardMeta: {
      showOnlyFields
    },
    resource: deal
  } = useBusinessCard()

  const allUsers = useSelector(getUsers)

  const showOwner = useMemo(() => showOnlyFields.includes('user') || showOnlyFields.includes('owner') || showOnlyFields.includes('team_id') || showOnlyFields.includes('user_id'), [showOnlyFields])

  const userOwner = useMemo(() => {
    if (showOnlyFields.includes('user') || showOnlyFields.includes('owner')) { return `${deal.user.first_name} ${deal.user.last_name}` }
    if (showOnlyFields.includes('user_id')) { return allUsers.find(user => user.id === deal.user_id)?.full_name }
  }, [deal, showOnlyFields])

  const teamOwner = useMemo(() => {
    if (!showOnlyFields.includes('team_id')) { return null }
    return deal.team?.name
  }
  , [deal, showOnlyFields])

  return (
    <>
      <SectionTitle>
        <Row>
          <Left>
            <RecordIcon className="fas fa-briefcase" />
          </Left>

          <Right data-cypress="business-card-deal-name" fullWidth>
            <Name />

            {showOwner && (userOwner || teamOwner) && (
              <Owner className="owner">
                <InterpunctJoin items={[userOwner, teamOwner].filter(Boolean)} />
              </Owner>
            )}
          </Right>
        </Row>
      </SectionTitle>
    </>
  )
}

export default Deal
