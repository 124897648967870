/* global Helpers */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import { H6 } from '@PipelineDeals/shared-react-components'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { getDealStatuses, getLeadSources } from 'selectors/Account'
import { useBusinessCard } from 'components/BusinessCard/context'

import CompanyName from 'components/BusinessCard/components/CompanyName'
import CreatedUpdated from 'components/BusinessCard/components/CreatedUpdated'
import DealStagePipeline from 'components/BusinessCard/components/DealStagePipeline'
import Description from 'components/BusinessCard/components/Description'
import PrimaryContact from 'components/BusinessCard/components/PrimaryContact'
import SimpleSection from 'components/BusinessCard/components/SimpleSection'
import Tags from 'components/BusinessCard/components/Tags'

const Deal = () => {
  const {
    cardMeta: {
      showAssociationsCount
    },
    resource: deal
  } = useBusinessCard()

  const dealStatuses = useSelector(getDealStatuses)
  const dealSources = useSelector(getLeadSources)

  const associationsCount = useMemo(() => {
    if (!deal.person_ids) { return null }
    const peopleCount = `(${deal.person_ids.length}) ${pluralize('Person', deal.person_ids.length)}`
    return (
      <Section>
        <Row>
          <Left />
          <Right>
            <H6>{peopleCount}</H6>
          </Right>
        </Row>
      </Section>
    )
  }, [deal])

  const dealStatus = useMemo(() => {
    if (!deal.status) { return null }

    const status = dealStatuses.find(ds => ds.id === deal.status)
    if (!status) { return null }

    return <span><i className="fas fa-circle" style={{ color: `#${status.hex_color}` }} /> {status.name}</span>
  }, [deal, dealStatuses])

  const dealSource = useMemo(() => {
    if (deal.source) { return deal.source.name }
    if (!deal.source_id) { return null }

    const source = dealSources.find(ds => ds.id === deal.source_id)
    return source?.name
  }, [deal, dealSources])

  return (
    <>
      <CompanyName />
      <CreatedUpdated />
      <DealStagePipeline />
      <SimpleSection field="status" value={dealStatus} label="status" />
      <SimpleSection
        field="value"
        value={deal.value ? <span className="green">{deal.currency_symbol || deal.currency?.symbol}{deal.value}</span> : null}
        label="amount"
      />

      <SimpleSection
        field="deal_value"
        value={deal.deal_value ? <span className="green">{deal.currency_symbol || deal.currency?.symbol}{deal.deal_value}</span> : null}
        label="amount"
      />

      <SimpleSection field="deal_loss_reason_id" value={deal.deal_loss_reason?.name} label="loss reason" />
      <SimpleSection field="deal_won_reason_id" value={deal.deal_won_reason?.name} label="won reason" />
      <SimpleSection field="probability" value={deal.probability ? `${deal.probability}%` : null} label="probability" />
      <SimpleSection field="expected_close_date" value={Helpers.formatDate(deal.expected_close_date)} label="expected close" />
      <SimpleSection field="closed_time" value={Helpers.formatDate(deal.closed_time)} label="actual close" />
      <SimpleSection field="source_id" value={dealSource} label="source" />
      <SimpleSection field="product_line_ids" value={deal.product_lines?.map(pl => pl.name).join(', ')} label="performance lane" />

      <PrimaryContact />
      <Tags />
      <Description />

      {showAssociationsCount && associationsCount}
    </>
  )
}

export default Deal
