/* global Helpers */
import React from 'react'

import {
  Section,
  Row,
  Left,
  Right,
  Interpunct
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const CreatedUpdated = () => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  const showCreated = showOnlyFields.includes('created') || showOnlyFields.includes('created_at')
  const showUpdated = showOnlyFields.includes('updated') || showOnlyFields.includes('updated_at')

  if (!showCreated && !showUpdated) return null

  return (
    <Section>
      <Row>
        <Left>
          {showCreated && 'crt'}{showCreated && showUpdated && ' / '}{showUpdated && 'upd'}
        </Left>
        <Right>
          {showCreated && Helpers.formatDate(resource.created_at)}{showCreated && showUpdated && <>&nbsp;<Interpunct />&nbsp;</>}{showUpdated && Helpers.formatDate(resource.updated_at)}
        </Right>
      </Row>
    </Section>
  )
}

export default CreatedUpdated
